import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

export const query = graphql`
  query IndustriesTemplateQuery($id: String!) {
    page: sanityIndustries(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 528)
        }
      }
      heroHeading
      _rawHeroText(resolveReferences: { maxDepth: 5 })
      challengesHeading
      challengesList {
        listItem
      }
      challengesImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 760)
        }
      }
      servicesHeading
      taxServicesHeading
      taxServicesText
      bookkeepingHeading
      bookkeepingText
      fractionalCFOHeading
      fractionalCFOText
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    tax: file(relativePath: { eq: "industry/tax.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 552)
      }
    }
    bookkeeping: file(relativePath: { eq: "industry/bookkeeping.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 552)
      }
    }
    fractionalCFO: file(relativePath: { eq: "industry/fractional-cfo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 552)
      }
    }
    horseHeadOrange: file(relativePath: { eq: "global/logo-head-orange.svg" }) {
      publicURL
    }
    rotatingWordsOrange: file(
      relativePath: { eq: "industry/rotating-words-taxes.svg" }
    ) {
      publicURL
    }
    horseHeadBlue: file(relativePath: { eq: "global/logo-head-blue.svg" }) {
      publicURL
    }
    rotatingWordsBlue: file(
      relativePath: { eq: "industry/rotating-words-books.svg" }
    ) {
      publicURL
    }
    horseHeadDarkBlue: file(
      relativePath: { eq: "global/logo-head-dark-blue.svg" }
    ) {
      publicURL
    }
    rotatingWordsDarkBlue: file(
      relativePath: { eq: "industry/rotating-words-cfo.svg" }
    ) {
      publicURL
    }
  }
`;

const IndustriesTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="mb-20 pt-10 md:mb-48 md:pt-14">
            <div className="container">
              <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-14 lg:gap-x-24">
                <div className="order-2 md:order-1">
                  <h1>{page.heroHeading}</h1>
                  {page._rawHeroText && (
                    <PortableText blocks={page._rawHeroText} />
                  )}
                  <ButtonSolid href="/get-a-quote/" text="Get a Quote" />
                </div>
                <div className="order-1 md:order-2">
                  <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                    <div className="absolute -top-4 -right-4 lg:-top-8 lg:-right-8">
                      <GatsbyImage
                        image={
                          data.orangeSquare.childImageSharp.gatsbyImageData
                        }
                        className="w-[86px] lg:w-[160px]"
                      />
                    </div>
                    {page.heroImage && (
                      <div className="relative z-10">
                        <GatsbyImage
                          image={page.heroImage.asset.gatsbyImageData}
                          alt={page.heroHeading}
                          className="z-0 w-full rounded-4xl"
                        />
                        <div className="absolute -left-1.5 top-1.5 z-[-10] h-full w-full rounded-3xl bg-secondary-500 md:-left-3 md:top-3"></div>
                      </div>
                    )}
                    <div className="absolute -bottom-8 -left-8 lg:-bottom-18 lg:-left-18">
                      <GatsbyImage
                        image={
                          data.orangeCrosses.childImageSharp.gatsbyImageData
                        }
                        className="w-[104px] lg:w-[192px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ValueProps />

          <section className="relative mb-20 bg-secondary-100 py-20 md:mb-32 md:py-32">
            <div className="container">
              <div className="mx-auto mb-10 md:hidden">
                {page.challengesImage && (
                  <GatsbyImage
                    image={page.challengesImage.asset.gatsbyImageData}
                    alt={page.challengesHeading}
                    className="z-0 w-full rounded-4xl"
                  />
                )}
              </div>

              <header className="mb-10 text-center md:mb-18">
                <h2>{page.challengesHeading}</h2>
              </header>

              <div className="grid items-center gap-y-3 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
                <div className="space-y-3 md:space-y-8">
                  {page.challengesList
                    .slice(0, Math.round(page.challengesList.length / 2))
                    .map((item, i) => {
                      return (
                        <div className="relative" key={i}>
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-right-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-r-0 after:border-transparent after:border-l-white md:ml-2 md:after:block lg:p-4">
                            {item.listItem}
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                      );
                    })}
                </div>

                <div className="mx-auto hidden md:block">
                  {page.challengesImage && (
                    <GatsbyImage
                      image={page.challengesImage.asset.gatsbyImageData}
                      alt={page.challengesHeading}
                      className="z-0 w-full rounded-4xl"
                    />
                  )}
                </div>

                <div className="space-y-3 md:space-y-8">
                  {page.challengesList
                    .slice(Math.round(page.challengesList.length / 2))
                    .map((item, i) => {
                      return (
                        <div className="relative" key={i}>
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-left-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-l-0 after:border-transparent after:border-r-white md:mr-2 md:after:block lg:p-4">
                            {item.listItem}
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 md:mb-32">
            <div className="container">
              <header className="mx-auto mb-14 max-w-[880px] text-center md:mb-28">
                <h2>{page.servicesHeading}</h2>
              </header>

              <div className="mb-20 grid items-center gap-y-6 md:mb-26 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="relative mr-1.5 md:mr-3">
                  <GatsbyImage
                    image={data.tax.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                  <div className="absolute -right-1.5 -top-1.5 z-[-10] h-full w-full rounded-4xl bg-secondary-500 md:right-3 md:-top-3"></div>
                </div>
                <div>
                  <div className="relative mb-6 flex h-16 w-16 items-center justify-center md:mb-5 md:h-20 md:w-20">
                    <img
                      src={data.rotatingWordsOrange.publicURL}
                      alt="Rotating words"
                      className="rotating absolute inset-0"
                    />
                    <img
                      src={data.horseHeadOrange.publicURL}
                      alt="Dark Horse logo"
                      className="w-6 md:w-[27px]"
                    />
                  </div>
                  <h3>{page.taxServicesHeading}</h3>
                  <p>{page.taxServicesText}</p>
                  <ButtonWithIcon
                    href="/small-business-tax-cpa/"
                    text="Learn More"
                  />
                </div>
              </div>

              <div className="mb-20 grid items-center gap-y-8 md:mb-26 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="order-2 md:order-1">
                  <div className="relative mb-6 flex h-16 w-16 items-center justify-center md:mb-5 md:h-20 md:w-20">
                    <img
                      src={data.rotatingWordsBlue.publicURL}
                      alt="Rotating words"
                      className="rotating absolute inset-0"
                    />
                    <img
                      src={data.horseHeadBlue.publicURL}
                      alt="Dark Horse logo"
                      className="w-6 md:w-[27px]"
                    />
                  </div>
                  <h3>{page.bookkeepingHeading}</h3>
                  <p>{page.bookkeepingText}</p>
                  <ButtonWithIcon
                    altStyle={2}
                    href="/small-business-bookkeeping-services/"
                    text="Learn More"
                  />
                </div>
                <div className="relative order-1 ml-1.5 md:order-2 md:ml-3">
                  <GatsbyImage
                    image={data.bookkeeping.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                  <div className="absolute right-1.5 -top-1.5 z-[-10] h-full w-full rounded-4xl bg-primary-500 md:right-3 md:-top-3"></div>
                </div>
              </div>

              <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="relative mr-1.5 md:mr-3">
                  <GatsbyImage
                    image={data.fractionalCFO.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                  <div className="absolute -right-1.5 -top-1.5 z-[-10] h-full w-full rounded-4xl bg-primary-800 md:right-3 md:-top-3"></div>
                </div>
                <div>
                  <div className="relative mb-6 flex h-16 w-16 items-center justify-center md:mb-5 md:h-20 md:w-20">
                    <img
                      src={data.rotatingWordsDarkBlue.publicURL}
                      alt="Rotating words"
                      className="rotating absolute inset-0"
                    />
                    <img
                      src={data.horseHeadDarkBlue.publicURL}
                      alt="Dark Horse logo"
                      className="w-6 md:w-[27px]"
                    />
                  </div>
                  <h3>{page.fractionalCFOHeading}</h3>
                  <p>{page.fractionalCFOText}</p>
                  <ButtonWithIcon
                    altStyle={3}
                    href="/fractional-cfo-services/"
                    text="Learn More"
                  />
                </div>
              </div>
            </div>
          </section>

          <Testimonials />
          <FindYourCPA />
          <Locations />
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default IndustriesTemplate;
